import React from "react";

const MainV1 = () => {
  return (
    <>
      <div className="pusher">
        <div className="ui inverted vertical masthead center aligned segment">
          <div className="ui text container">
            <h1 className="ui inverted header huge">ROAD</h1>
            <h2 className="ui inverted header small">
              Recording Ontology for Affective-related Datasets
            </h2>
          </div>
        </div>

        <div className="ui vertical stripe segment">
          <div className="ui middle aligned stackable grid container">
            <div className="row">
              <div className="eight wide column">
                <h3 className="ui header">Ontology</h3>
                <p>
                  ROAD (Recording Ontology for Affective-related Datasets) is an
                  ontology that provides a conceptualization for Affective
                  Computing datasets. The goal of this project is to create an
                  ontology that would help researchers and engineers manage data
                  collected during various Affective Computing studies at
                  different stages of their lives.{" "}
                </p>
                <p>
                  {" "}
                  We hope that ROAD will enable scientists and engineers
                  conducting research in Affective Computing to achieve the
                  following goals: :
                  <ul>
                    <li>Improved ability to reuse the results</li>
                    <li>Ability to reproduce results</li>
                    <li>Increased accountability</li>
                    <li>Ability to construct the tool set</li>
                  </ul>
                </p>
              </div>
              <div className="six wide right floated column">
                <img
                  src="ontology_screen.png"
                  className="ui bordered rounded image"
                  alt="ROAD Ontology"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="ui vertical stripe quote segment">
          <div className="ui equal width stackable internally celled grid">
            <div className="center aligned row">
              <div className="column teal">
                <h3>Web Ontology Language </h3>

                <p>
                  The ROAD ontology was expressed in OWL DL sublanguage.
                  <br />
                  It allows to achive maximum expressivness without losing
                  computational completness of reasoning systems
                </p>
              </div>
              <div className="column blue">
                <h3>License</h3>
                <img
                  className="ui tiny right floated image"
                  src="cc_icon_white_x2.png"
                  alt="CC Licence"
                />
                <img
                  className="ui tiny right floated image"
                  src="attribution_icon_white_x2.png"
                  alt="CC Licence"
                />
                <p>
                  This work is licensed under a&nbsp;
                  <a
                    style={{ color: "white", textDecoration: "underline" }}
                    rel="license"
                    href="http://creativecommons.org/licenses/by/4.0/"
                  >
                    Creative Commons Attribution 4.0 International License
                  </a>
                  .
                </p>
                <div className="ui compact secondary inverted blue segment left aligned">
                  If you are using the ROAD ontology in published study, please
                  cite the paper below:
                  <ul>
                    <li>
                      Zawadzka T., Waloszek W., Karpus A., Zapalowska S., Wrobel
                      MR. <br />
                      "Ontological Model for Contextual Data Defining Time Series for Emotion Recognition and Analysis," in IEEE Access, vol. 9, pp. 166674-166694, 2021, doi: <a href='https://doi.org/10.1109/ACCESS.2021.3132728' style={{color: 'lightgray' }}>10.1109/ACCESS.2021.3132728</a>.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ui vertical stripe segment">
          <div className="ui text container">
            <h3 className="ui header">Core Ontology</h3>
            <img
              src="core.png"
              className="ui rounded image"
              alt="ROAD Core Ontology"
            />
            <div className="ui list">
              <div className="item">
                <i className="book icon"></i>
                <div className="content">
                  <div className="header">
                    <a className="ui  item" href={"/core"}>
                      {"ROAD Core Ontology Documentation"}
                    </a>
                  </div>
                </div>
              </div>
              <div className="item">
                <i className="book icon"></i>
                <div className="content">
                  <div className="header">
                  <a className="ui  item" href={"/documentation/widoco/core"}>
                      {"ROAD Core Ontology Documentation (WIDOCO)"}
                    </a>
                  </div>
                </div>
              </div>
              <div className="item">
                <i className="file icon"></i>
                <div className="content">
                  <div className="header">
                  <a
                      className="ui  item"
                      href={"/documentation/owlAC.owl"}
                    >
                      ROAD Core Ontology OWL file
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <p>This is previous version of the RAOD ontology, the newest one is available under: <a href='/'>https://road.affectivese.org/</a>.</p>

            <h3 className="ui header">Extension points</h3>
            <p>
              The aim of the extension points is to provide possibility of
              adjusting the contextual information to the specified needs.
            </p>
            <div className="ui list celled">
              <div className="item">
                <i className="folder open icon"></i>
                <div className="content">
                  <div className="header">User defined property</div>
                  <div className="description">
                    Allows to add user-defined properties for some of the
                    existing concepts.
                  </div>
                  <div className="ui list celled">
                    <DocItem
                      name="Property"
                      desc="User-defined property of the notion."
                      link="property"
                      owl="owlAC.propertyConcept.owl"
                    />
                  </div>
                </div>
              </div>
              <div className="item">
                <i className="folder open icon"></i>
                <div className="content">
                  <div className="header">User defined models</div>
                  <div className="description">
                    Allows to define various models for the particular concepts.
                  </div>
                  <div className="ui list celled">
                    <div className="item">
                      <i className="folder open icon"></i>
                      <div className="content">
                        <div className="header">Emotional models</div>
                        {/* <div className="description">
                          Individual component CSS and JS
                        </div> */}
                        <div className="ui list celled">
                          <DocItem
                            name="PAD Model"
                            desc="Specific measure representing emotional state
                            for two-dimensional model, this is an emotional
                            state measure."
                            link="models.emotions.pad"
                            owl="owlAC.models.emotionalMeasures.pad.owl"
                          />
                          <DocItem
                            name="Ekman Model"
                            desc="Specific measure representing emotional state
                            for one of the basic Ekman's emotions."
                            link="models.emotions.ekman"
                            owl="owlAC.models.emotionalMeasures.ekman.owl"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ui list celled">
                    <div className="item">
                      <i className="folder open icon"></i>
                      <div className="content">
                        <div className="header">Apperance models</div>
                        {/* <div className="description">
                          Individual component CSS and JS
                        </div> */}
                        <div className="ui list celled">
                          <DocItem
                            name="Occlusion Model"
                            desc="A model of an apperance allowing to describe
                            occlussions: beard, moustache and glasses."
                            link="models.apperance.occlusion"
                            owl="owlAC.models.appearenceOcclusion.owl"
                          />
                          <DocItem
                            name="Somatotype Model"
                            desc="A model of apperance allowing to describe
                            somatotypes. Simple version of somatotypes
                            taxonomy introduced by W. H. Sheldon. The model
                            does not allow to calculate values of
                            hasSomatotype... roles. These values must be
                            precalculated and directly modelled."
                            link="models.apperance.somatype"
                            owl="owlAC.models.appearanceSomatotypeModel.owl"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ui list celled">
                    <div className="item">
                      <i className="folder open icon"></i>
                      <div className="content">
                        <div className="header">Arrangment models</div>
                        {/* <div className="description">
                          Individual component CSS and JS
                        </div> */}
                        <div className="ui list celled">
                          <DocItem
                            name="Interpersonal Distance Model"
                            desc="A model of two persons arrangment allowing to
                            describe impersonal distance categorization
                            introduced by T. T. Hall. The model assumes that
                            there are four zones: intimate, casual,
                            socioconsultive and public."
                            link="models.arrangement.interpersonal"
                            owl="owlAC.models.arrangementInterpersonalDistanceModel.owl"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ui list celled">
                    <div className="item">
                      <i className="folder open icon"></i>
                      <div className="content">
                        <div className="header">Personality models</div>
                        {/* <div className="description">
                          Individual component CSS and JS
                        </div> */}
                        <div className="ui list celled">
                          <DocItem
                            name="Big Five Model"
                            desc="An implementation of the Big Five Model (Big
                              Factor Model) which allows to define five
                              factors: openess, neuroticism, agreeablesness,
                              conscientiousness and extroversion as a value in
                              the range &lt;0,1&gt;."
                            link="models.personality.bigFive"
                            owl="owlAC.models.bigFiveModel.owl"
                          />
                          <DocItem
                            name="PANAS Model"
                            desc="Simple implementation of PANAS model (Postive
                                and Negative Affect Schedule) allowing to
                                describe possitive and negative affect expressed
                                as a value in &lt;0,1&gt;range."
                            link="models.personality.panas"
                            owl="owlAC.models.panasModel.owl"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ui horizontal divider"></div>
            <h3 className="ui header">Datasets</h3>
            <p>Dataset for Ekman's emotions recognized for AMIGOS dataset</p>
            <div className="ui list celled">
              <DatasetItem
                name="Inconsistency dataset"
                desc="The dataset prepared at Gdańsk University of Technology, being a result of the experiment, in which facial expression modality was chosen and four observation channels - participants’ faces were recorded with cameras located in four corners of the screen. Each recording was used to recognize emotional states by three systems Noldus Face Reader, QuantumLab Express Engine and Luxand-based. Nine emotional states were recognized by FaceReader: six basic Ekman emotions, neutral state as well as valence and arousal from PAD model. QuantumLab Express Engine software provides five out of six basic emotions (without fear) and a neutral state, while Luxand library provides six basic emotions and a neutral state. The aim of the experiment was to analyse the consistency between recognized emotions from various cameras and systems. Additionally, the influence of such variables as sex, glasses, moustache and beard on consistency was analysed. In the experiment twelve participants took part. Their faces were recorded during executing previously prepared tasks on e-learning platform."
                link="inconsistencyDataset.owl"
                type="OWL"
                licence="Zawadzka, T., Waloszek, W., & Zawadzki, M. (2023). Ontology-Based Method for Analysis of Inconsistency Factors in Emotion Recognition. In Information Systems Development, Organizational Aspects and Societal Trends (ISD2023 Proceedings)"
                doi="10.62036/ISD.2023.48"
              />

              <DatasetItem
                name="Dataset for Ekman's emotions recognized for AMIGOS dataset"
                desc="The dataset obtained from the experiment analyzing inconsistency between recognized emotional states. The experiment was performed at Gdańsk University of Technology, but it was carried out for the AMIGOS dataset. The emotions were obtained  from Graph Neural Networks (GNN) differently configured and incorporating unimodal or multimodal approaches. Firstly, the six basic emotions and neutral state were recognized from facial expressions using Noldus Face Reader. Secondly, the same emotions were recognized by GraphEmotionNet using unimodal (from EEG only) and multimodal approach (from EEG, ECG and GSR) for the two configurations of GNN with and without class weights that increase the sensitivity of Ekman’s emotions more than the neutral state."
                link="GraphNeuralNetwork.rdf"
                type="RDF"
                licence="Wierciński, T., Rock, M., Zwierzycki, R., Zawadzka, T., & Zawadzki, M. (2022). Emotion recognition from physiological channels using graph neural network. Sensors, 22(8)."
                doi="10.3390/s22082980"
                licence1="Miranda-Correa, J. A., Abadi, M. K., Sebe, N., & Patras, I. (2018). Amigos: A dataset for affect, personality and mood research on individuals and groups. IEEE transactions on affective computing, 12(2)."
                doi1="10.1109/TAFFC.2018.2884461"
              />
            </div>
            <div className="ui horizontal divider"></div>
          </div>
        </div>

        <div className="ui vertical footer segment">
          <div className="ui container">
            <div className="ui stackable equal height stackable grid">
              <div className="six wide column">
                <a href="https://www.pg.edu.pl/" alt="GDANSK Tech">
                  <img
                    className="ui tiny right floated image"
                    src="logo_pg.png"
                    alt="GDANSK Tech"
                  />
                </a>
              </div>
              <div className="three wide column">
                <a href="https://www.eti.pg.edu.pl/" alt="ETI">
                  <img
                    className="ui tiny right floated image"
                    src="logo.eti.png"
                    alt="WETI"
                  />
                </a>
              </div>
              <div className="three wide column">
                <a href="http://emorg.eu/" alt="EMORG">
                  <img
                    className="ui tiny right floated image"
                    src="logo_emorg.png"
                    alt="EMORG"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DocItem = ({ name, link, widoco, desc, owl }) => {
  return (
    <div className="item">
      <i className="book icon"></i>
      <div className="content">
        <div className="header">
          <a className="ui  item" href={link}>
            {name + " documentation"}
          </a>
          <br />
          <a className="ui  item" href={"/documentation/widoco/" + link}>
            {name + " documentation (WIDOCO)"}
          </a>
          <br />
          <a className="ui  item" href={"/documentation/" + owl}>
            (OWL file)
          </a>
        </div>
        <div className="description">{desc}</div>
      </div>
    </div>
  );
};

const DatasetItem = ({ name, link, type, desc, licence, doi, licence1, doi1 }) => {
  return (
    <div className="item">
      <i className="book icon"></i>
      <div className="content">
        <div className="header">
          <a className="ui  item" href={"/datasets/" + link}>
            {name + " (" +type+ " file)"}
          </a>
        </div>
        <div className="ui inverted segment blue secondary">
          This work is licensed under a Creative Commons Attribution 4.0
          International License.
          <br />
          If you are using the dataset in published study, please cite the paper
          below:
          <br />
          <ul>
            <li>{licence}, doi: <a style={{color: 'white', textDecoration: 'underline'}} href={"https://doi.org/"+doi}>{doi}</a></li>
            {licence1 &&
              <li>{licence1}, doi: <a style={{color: 'white', textDecoration: 'underline'}} href={"https://doi.org/"+doi1}>{doi1}</a></li>
            }
          </ul>
        </div>
        <div className="description">{desc}</div>
      </div>
    </div>
  );
};

export default MainV1;
