import React, { useEffect, useState } from "react";
import "./VideoItem.css";

const NavItems = ({ fileName, onItemSelect, group }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (group.split("/")[0]) {
      console.log(fileName);
      fetch(fileName)
        .then(function (response) {
          return response.text();
        })
        .then(function (data) {
          console.log(data)
          var innerItems = [];
          var parser = new DOMParser();
          var htmlDoc = parser.parseFromString(data, "text/html");
          var listItem = htmlDoc.querySelectorAll(".asserted > a");
          for (var i = 0; i < listItem.length; i++) {
            innerItems.push({
              label: listItem[i].innerText,
              url: group + "/" + listItem[i].getAttribute("href"),
              key: i,
            });
          }
          setItems(innerItems);
        });
    }
  }, [fileName, group]);

  return <NavCollection items={items} onItemSelect={onItemSelect} />;
};

const NavCollection = ({ items, onItemSelect }) => {
  return (
    <>
      {items.map(({ label, url, key }) => (
        <NavItem
          key={key}
          label={label}
          url={url}
          onItemSelect={onItemSelect}
        />
      ))}
    </>
  );
};

const NavItem = ({ label, url, onItemSelect }) => {
  // console.log(url);
  return (
    // <a href="#" onClick={() => onItemSelect(url)} className="item">
    <a href={`${process.env.PUBLIC_URL}/` + url} className="item">
      {label}
    </a>
  );
};
export default NavItems;
