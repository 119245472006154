import React from "react";
import NavItems from "./NavItems";

const Navigation = ({ onComponentSelect, ontology }) => {
  var baseUrl = "/documentation/"+ontology;
  return (
    <div className="ui visible inverted left vertical sidebar menu">
      <div className="item">
      <a href="/"><div className="header">ROAD</div></a>
      </div>
      <div className="item">
        <div className="header">Classes</div>
        <div className="menu">
          <NavItems
            fileName={baseUrl+"/classes/index.html"}
            onItemSelect={onComponentSelect}
            group={ontology+"/classes"}
          />
        </div>
      </div>

      <div className="item">
        <div className="header">Object Properties</div>
        <div className="menu">
          <NavItems
            fileName={baseUrl+"/objectproperties/index.html"}
            onItemSelect={onComponentSelect}
            group={ontology+"/objectproperties"}
          />
        </div>
      </div>

      <div className="item">
        <div className="header">Data Properties</div>
        <div className="menu">
          <NavItems
            fileName={baseUrl+"/dataproperties/index.html"}
            onItemSelect={onComponentSelect}
            group={ontology+"/dataproperties"}
          />
        </div>
      </div>

      <div className="item">
        <div className="header">Annotation Properties</div>
        <div className="menu">
          <NavItems
            fileName={baseUrl+"/annotationproperties/index.html"}
            onItemSelect={onComponentSelect}
            group={ontology+"/annotationproperties"}
          />
        </div>
      </div>

      <div className="item">
        <div className="header">Individuals </div>
        <div className="menu">
          <NavItems
            fileName={baseUrl+"/individuals/index.html"}
            onItemSelect={onComponentSelect}
            group={ontology+"/individuals"}
          />
        </div>
      </div>

      <div className="item">
        <div className="header">Datatypes </div>
        <div className="menu">
          <NavItems
            fileName={baseUrl+"/datatypes/index.html"}
            onItemSelect={onComponentSelect}
            group={ontology+"/datatypes"}
          />
        </div>
      </div>
    </div>
  );
};

export default Navigation;
