import React from "react";
import Main from "./Main";
import MainV1 from "./Main_v1";
import Navigation from "./Navigation";

class App extends React.Component {
  state = {
    ontology: null,
    content: null,
    main: true,
    mainv1: false,
  };

  componentDidMount() {
    var url = window.location.pathname.split("/");
    console.log("URL", url, url.length);

    if(url[1] === "v1") {
      this.setState({ main: false });
      this.setState({ mainv1: true });     
    } else if (url.length <= 3) {
      this.setState({ ontology: url[1] });
      this.onComponentSelect(url[1] + "/ontologies/index.html");
      if (url[1] !== "") {
        this.setState({ main: false });
        this.setState({ mainv1: false });
      }
    } else if (url.length > 3) {
      console.log(url);
      this.setState({ main: false });
      this.setState({ mainv1: false });
      this.setState({ ontology: url[1] });
      this.onComponentSelect(url[1] + "/" + url[2] + "/" + url[3]);
    }
  }

  onComponentSelect = (url) => {
    fetch("/documentation/" + url)
      .then(function (response) {
        return response.text();
      })
      .then((data) => {
        console.log("DATA", data);
        var parser = new DOMParser();
        var htmlDoc = parser.parseFromString(data, "text/html");
        var listItem = htmlDoc.querySelectorAll(".summary");
        if (listItem.length > 0) {
          this.setState({ content: listItem[0].innerHTML });
        } else {
          listItem = htmlDoc.querySelectorAll(".active-ontology-uri");
          if (listItem.length > 0) {
            this.setState({
              content:
                "<h2 style='text-align: center'>" +
                listItem[0].innerHTML +
                "</h2>",
            });
          }
        }
      });
    console.log("clicked!!!", "/documentation/" + url);
  };

  render() {
    if (this.state.main) {
      return <Main />;
    } else if (this.state.mainv1) {
      return <MainV1/>;
    } else {
      return (
        <Browser
          onComponentSelect={this.onComponentSelect}
          ontology={this.state.ontology}
          content={this.state.content}
        />
      );
    }

    // <Main />
  }
}

const Browser = ({ onComponentSelect, ontology, content }) => {
  return (
    <>
      <div className="ui bottom attached segment">
        <Navigation onComponentSelect={onComponentSelect} ontology={ontology} />

        <div className="pusher">
          <div className="ui basic segment">
            <div className="ui divider"></div>
            <a href="/">
              <h1 className="ui header center aligned">ROAD</h1>
            </a>
            <h2 className="ui header center aligned">
              Recording Ontology for Affective-related Datasets
            </h2>
            <div className="ui divider"></div>
            <div className="ui grid">
              <div className="ui row">
                <div
                  className="ui container"
                  dangerouslySetInnerHTML={{ __html: content }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
