import React from "react";

const Main = () => {
  return (
    <>
      <div className="pusher">
        <div className="ui inverted vertical masthead center aligned segment">
          <div className="ui text container">
            <h1 className="ui inverted header huge">ROAD</h1>
            <h2 className="ui inverted header small">
              Recording Ontology for Affective-related Datasets
            </h2>
          </div>
        </div>

        <div className="ui vertical stripe segment">
          <div className="ui middle aligned stackable grid container">
            <div className="row">
              <div className="eight wide column">
                <h3 className="ui header">Ontology</h3>
                <p>
                  ROAD (Recording Ontology for Affective-related Datasets) is an
                  ontology that provides a conceptualization for Affective
                  Computing datasets. The goal of this project is to create an
                  ontology that would help researchers and engineers manage data
                  collected during various Affective Computing studies at
                  different stages of their lives.{" "}
                </p>
                <p>
                  {" "}
                  We hope that ROAD will enable scientists and engineers
                  conducting research in Affective Computing to achieve the
                  following goals: :
                  <ul>
                    <li>Improved ability to reuse the results</li>
                    <li>Ability to reproduce results</li>
                    <li>Increased accountability</li>
                    <li>Ability to construct the tool set</li>
                  </ul>
                </p>
              </div>
              <div className="six wide right floated column">
                <img
                  src="ontology_screen.png"
                  className="ui bordered rounded image"
                  alt="ROAD Ontology"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="ui vertical stripe quote segment">
          <div className="ui equal width stackable internally celled grid">
            <div className="center aligned row">
              <div className="column teal">
                <h3>Web Ontology Language </h3>

                <p>
                  The ROAD ontology was expressed in OWL DL sublanguage.
                  <br />
                  It allows to achive maximum expressivness without losing
                  computational completness of reasoning systems
                </p>
              </div>
              <div className="column blue">
                <h3>License</h3>
                <img
                  className="ui tiny right floated image"
                  src="cc_icon_white_x2.png"
                  alt="CC Licence"
                />
                <img
                  className="ui tiny right floated image"
                  src="attribution_icon_white_x2.png"
                  alt="CC Licence"
                />
                <p>
                  This work is licensed under a&nbsp;
                  <a
                    style={{ color: "white", textDecoration: "underline" }}
                    rel="license"
                    href="http://creativecommons.org/licenses/by/4.0/"
                  >
                    Creative Commons Attribution 4.0 International License
                  </a>
                  .
                </p>
                <div className="ui compact secondary inverted blue segment left aligned">
                  If you are using the ROAD ontology in published study, please
                  cite the paper below:
                  <ul>
                  <li>
                      Zawadzka T., Wiercinski T., Waloszek W., Wrobel MR. <br />
                      "Ontological Modeling for Contextual Data Describing Signals Obtained from Electrodermal Activity for Emotion Recognition and Analysis".

                    </li>
                    <li>
                      Zawadzka T., Waloszek W., Karpus A., Zapalowska S., Wrobel
                      MR. <br />
                      "Ontological Model for Contextual Data Defining Time Series for Emotion Recognition and Analysis," in IEEE Access, vol. 9, pp. 166674-166694, 2021, doi: <a href='https://doi.org/10.1109/ACCESS.2021.3132728' style={{color: 'lightgray' }}>10.1109/ACCESS.2021.3132728</a>.

                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ui vertical stripe segment">
          <div className="ui text container">
            <h3 className="ui header">Main module</h3>
            <img
              src="main.png"
              className="ui rounded image"
              alt="ROAD Main Ontology"
            />
            The main module that contains concepts and roles that are the core of the ROAD ontology.
Concepts and roles defined in this module are common for various types of signals and Affective
Computing experiments.
            <div className="ui list">
              <div className="item">
                <i className="book icon"></i>
                <div className="content">
                  <div className="header">
                    <a
                      className="ui  item"
                      href={"/documentation/widoco2/Main"}
                    >
                      {"ROAD Core Ontology Documentation"}
                    </a>
                  </div>
                </div>
              </div>
              <div className="item">
                <i className="file icon"></i>
                <div className="content">
                  <div className="header">
                    <a className="ui  item" href={"/documentation/Main.owl"}>
                      ROAD Core Ontology OWL file
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <p>Documentation for the previous version of the ontology is available under: <a href='v1'>https://road.affectivese.org/v1</a>.</p>


            <h3 className="ui header">Extension points</h3>
            <p>
              The aim of the extension points is to provide possibility of
              adjusting the contextual information to the specified needs.
            </p>
            <div className="ui list celled">
              <div className="item">
                <i className="folder open icon"></i>
                <div className="content">
                  <div className="header">User defined property</div>
                  <div className="description">
                    Allows to add user-defined properties for some of the
                    existing concepts.
                  </div>
                  <div className="ui list celled">
                    <DocItem
                      name="Properties"
                      desc="The module that assigns additional properties to various concepts modeled as key-value pairs."
                      link="Properties"
                      owl="Properties.owl"
                    />
                  </div>
                </div>
              </div>
              <div className="item">
                <i className="folder open icon"></i>
                <div className="content">
                  <div className="header">Configuration</div>
                  {/* <div className="description">
                          Individual component CSS and JS
                        </div> */}
                  <div className="ui list celled">
                    <DocItem
                      name="Configurations"
                      desc="This module contains a basic abstraction for hardware configurations and configuration sources."
                      link="Configurations"
                      owl="Configurations.owl"
                    />
                    <DocItem
                      name="Configurations-ssn"
                      desc="The module that imports Configurations and SSN/SOSA ontologies and creates relations between the classes from both (stating that SSN specifications are valid descriptions of ROAD configurations), effectively being a bridge between the ROAD and SSN/SOSA ontologies."
                      link="Configurations-ssn"
                      owl="Configurations-ssn.owl"
                    />
                  </div>
                </div>
              </div>
              <div className="item">
                <i className="folder open icon"></i>
                <div className="content">
                  <div className="header">Stimulus</div>
                  {/* <div className="description">
                          Individual component CSS and JS
                        </div> */}
                  <div className="ui list celled">
                    <DocItem
                      name="Stimulus"
                      desc=" The module that defines stimuli and assign them to the special types of activities: baseline and stimulated."
                      link="Stimulus"
                      owl="Stimulus.owl"
                    />
                  </div>
                </div>
              </div>

              <div className="item">
                <i className="folder open icon"></i>
                <div className="content">
                  <div className="header">User defined models</div>
                  <div className="description">
                    Allows to define various models for the particular concepts.
                  </div>

                  <div className="ui list celled">
                    <div className="item">
                      <i className="folder open icon"></i>
                      <div className="content">
                        <div className="header">Measures models</div>
                        <div className="ui list celled">
                          <div className="content">
                            <div className="ui list celled">
                              <DocItem
                                name="General Measures Model"
                                desc="The module that implements the measure extension point introducing a unified measure common for various types of signals."
                                link="Models.Measures.General"
                                owl="Models.Measures.General.owl"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="ui list celled">
                          <div className="item">
                            <i className="folder open icon"></i>
                            <div className="content">
                              <div className="header">Emotional models</div>
                              {/* <div className="description">
                          Individual component CSS and JS
                        </div> */}
                              <div className="ui list celled">
                                <DocItem
                                  name="PAD Model"
                                  desc="The module that implements the emotion measure extension point introducing measure names characteristic for the PAD emotion model."
                                  link="Models.Measures.Emotion.PAD"
                                  owl="Models.Measures.Emotion.PAD.owl"
                                />
                                <DocItem
                                  name="Ekman Model"
                                  desc="The module that implements the emotion measure extension point introducing the basic Ekman's emotion states."
                                  link="Models.Measures.Emotion.Ekman"
                                  owl="Models.Measures.Emotion.Ekman.owl"
                                />
                                <DocItem
                                  name="Neutral Model"
                                  desc="The module that implements the emotion measure extension point introducing the neutral state."
                                  link="Models.Measures.Emotion.Neutral"
                                  owl="Models.Measures.Emotion.Neutral.owl"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="ui list celled">
                          <div className="item">
                            <i className="folder open icon"></i>
                            <div className="content">
                              <div className="header">
                                Signal dependent models
                              </div>
                              {/* <div className="description">
                          Individual component CSS and JS
                        </div> */}
                              <div className="ui list celled">
                                <DocItem
                                  name="EDA Measures Model"
                                  desc="The module that implements the measure extension point introducing unified measure names for EDA signals."
                                  link="Models.Measures.SignalDependent.EDA"
                                  owl="Models.Measures.SignalDependent.EDA.owl"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ui list celled">
                    <div className="item">
                      <i className="folder open icon"></i>
                      <div className="content">
                        <div className="header">Apperance models</div>
                        {/* <div className="description">
                          Individual component CSS and JS
                        </div> */}
                        <div className="ui list celled">
                          <DocItem
                            name="Occlusion Model"
                            desc="The module that implements the appearance extension point introducing the occlusion model containing information about such occlusions as beard, moustache and glasses."
                            link="Models.Appearance.Occlusion"
                            owl="Models.Appearance.Occlusion.owl"
                          />
                          <DocItem
                            name="Somatotype Model"
                            desc="The module that implements the appearance extension point introducing the somatotype model."
                            link="Models.Appearance.Somatotype"
                            owl="Models.Appearance.Somatotype.owl"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ui list celled">
                    <div className="item">
                      <i className="folder open icon"></i>
                      <div className="content">
                        <div className="header">Arrangment models</div>
                        {/* <div className="description">
                          Individual component CSS and JS
                        </div> */}
                        <div className="ui list celled">
                          <DocItem
                            name="Interpersonal Distance Model"
                            desc="The module that implements the arrangement extension point introducing the arrangement model describing the distance between participants of the experiment."
                            link="Models.Arrangement.InterpersonalDistance"
                            owl="Models.Arrangement.InterpersonalDistance.owl"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ui list celled">
                    <div className="item">
                      <i className="folder open icon"></i>
                      <div className="content">
                        <div className="header">Personality models</div>
                        {/* <div className="description">
                          Individual component CSS and JS
                        </div> */}
                        <div className="ui list celled">
                          <DocItem
                            name="Big Five Model"
                            desc="The module that implements the personality extension point introducing the big five model."
                            link="Models.Personality.BigFive"
                            owl="Models.Personality.BigFive.owl"
                          />
                          <DocItem
                            name="PANAS Model"
                            desc="The module that implements the personality extension point introducing the Panas model."
                            link="Models.Personality.Panas"
                            owl="Models.Personality.Panas"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ui list celled">
                    <div className="item">
                      <i className="folder open icon"></i>
                      <div className="content">
                        <div className="header">Race models</div>
                        {/* <div className="description">
                          Individual component CSS and JS
                        </div> */}
                        <div className="ui list celled">
                          <DocItem
                            name="Blumenbach Race Model"
                            desc="The module that implements the race extension point introducing the race categorization defined by Blumenbach."
                            link="Models.Race.Blumenbach"
                            owl="Models.Race.Blumenbach.owl"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ui horizontal divider"></div>
            <h3 className="ui header">Datasets</h3>
            <p>Datasets described with ROAD ontology.</p>
            <div className="ui list celled">
            <DatasetItem
                name="ABox.MahnobHci"
                desc="The excerpt of MAHNOB-HCI dataset containing part of the observation of participant 1 (represented as subject no 1). The whole observation (carried out on 2009-07-09 and starting at 17:54:17) consists of 40 activities, alternating between one unstimulated and one stimulated, according to the information given in the manual to MAHNOB-HCI. The sequence of the two sessions(one unstimulated and one stimulated) is modeled. The sessions represented in MAHNOB-HCI as 3 (unstimulated) and 4 (stimulated) were modeled. The two emotions represented in the Ekman model are assigned to the applied stimulus in session 4, i.e. anger and sadness. The observed participant is a male person, without glasses, with beard and moustache. The ethnicity of the participant is Caucasian and he is of Greek nationality. He was born on 1978-12-8. The stimulus is an excerpt of The Pianist movie lasting 1 minute 17 seconds and 1 millisecond. The excerpt of the movie starts at 0:54:33.3 and ends at 0:55:50.4. The name of the file with the video is 55.avi. Sessions 3 and 4 start with the video presentation and for session 4 the tagging process then follows. Session 3 is a bit longer than the video duration as it is calculated to the beginning of the presentation of the video stimulus in session 4. The video presented in session 3 is recorded in the file seagulls Final.avi. The start and end times of the video presentation are given as well as the start and end times of the sessions. The other information are common for all observations i.e. the device used is Biosemi active II with electrodes positioned on the distal phalanges of the middle and index fingers. The measured value was resistance expressed in Ohms. "
                link="ABox.MahnobHci.owl"
                licence="M. Soleymani, J. Lichtenauer, T. Pun and M. Pantic, 'A Multi­Modal Affective Database for 
                Affect Recognition and Implicit Tagging', IEEE Transactions on Affective Computing, Special 
                Issue: Naturalistic Affect Resources, 2011"
              />
            </div>
            <div className="ui horizontal divider"></div>
          </div>
        </div>

        <div className="ui vertical footer segment">
          <div className="ui container">
            <div className="ui stackable equal height stackable grid">
              <div className="six wide column">
                <a href="https://www.pg.edu.pl/" alt="GDANSK Tech">
                  <img
                    className="ui tiny right floated image"
                    src="logo_pg.png"
                    alt="GDANSK Tech"
                  />
                </a>
              </div>
              <div className="three wide column">
                <a href="https://www.eti.pg.edu.pl/" alt="ETI">
                  <img
                    className="ui tiny right floated image"
                    src="logo.eti.png"
                    alt="WETI"
                  />
                </a>
              </div>
              <div className="three wide column">
                <a href="http://emorg.eu/" alt="EMORG">
                  <img
                    className="ui tiny right floated image"
                    src="logo_emorg.png"
                    alt="EMORG"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DocItem = ({ name, link, widoco, desc, owl }) => {
  return (
    <div className="item">
      <i className="book icon"></i>
      <div className="content">
        <div className="header">
          <a className="ui  item" href={"/documentation/widoco2/" + link}>
            {name + " documentation"}
          </a>
          <br />
          <a className="ui  item" href={"/documentation/" + owl}>
            (OWL file)
          </a>
        </div>
        <div className="description">{desc}</div>
      </div>
    </div>
  );
};

const DatasetItem = ({ name, link, desc, licence }) => {
  return (
    <div className="item">
      <i className="book icon"></i>
      <div className="content">
        <div className="header">
          <a className="ui  item" href={"/documentation/" + link}>
            {name + " (OWL file)"}
          </a>
        </div>
        <div className="ui inverted segment blue secondary">
          This work is based on the <a href='https://mahnob-db.eu/hci-tagging/' style={{color: 'lightgray'}}>MAHNOB HCI­Tagging Database</a>, share under a <a href='eula.pdf' style={{color: 'lightgray'}}>End User License Agreement (EULA)</a>. 
          The research uses the MAHNOB Database collected by Professor 
Pantic and the iBUG group at Imperial College London, and in part collected in collaboration 
with Prof. Pun and his team of University of Geneva, in the scope of  MAHNOB project 
financially supported by the European Research Council under the European Community's 7th 
Framework Programme (FP7/2007­2013) / ERC Starting Grant agreement No. 203143.
          <br />

          If you are using the dataset in published study, please cite the paper
          below:
          <br />
          <ul>
            <li>{licence}</li>
          </ul>
        </div>
        <div className="description">{desc}</div>
      </div>
    </div>
  );
};

export default Main;
